'use client'

import { LuneTheme } from '@lune-fe/lune-ui-lib'
import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { FC, ReactNode } from 'react'

import { themeWithNewBreakpoints } from '@/app/ThemeRegistry'
import FullScreenOnMobileWrapper from '@/components/FullScreenOnMobileWrapper'
import useDevice from '@/hooks/useDevice'
import { optimizedImagePath } from '@/utills'

export const getBackgroundMaxWidth = (): number => {
    const { smScreen, mdScreen, xsScreen } = useDevice()

    // Assuming a Retina-like display, so pixel ratio of 2. Can we read this information
    // from somewhere?
    const assumedDevicePixelRatio = 2
    const breakpoints = themeWithNewBreakpoints.breakpoints
    let backgroundMaxWidth: number
    if (xsScreen) {
        backgroundMaxWidth = breakpoints.values.sm * assumedDevicePixelRatio
    } else if (smScreen) {
        backgroundMaxWidth = breakpoints.values.md * assumedDevicePixelRatio
    } else if (mdScreen) {
        backgroundMaxWidth = breakpoints.values.lg * assumedDevicePixelRatio
    } else {
        // We have less granularity here at the high end of the spectrum as (as of 2024-01-08)
        // we don't have "lgScreen" available in useDevice so we have to jump straight to the
        // largest reasonable size.
        //
        // 1920 won't be enough for really large displays but our original images are likely
        // smaller than that anyway (pixel ratio considering).
        backgroundMaxWidth = 1920 * assumedDevicePixelRatio
    }
    return backgroundMaxWidth
}

const TwoColumnsWithBackgroundImage: FC<{
    backgroundImage?: string
    mobileBackgroundImage?: string
    leftSide: ReactNode
    rightSide?: ReactNode
    reversedOnMobile?: boolean
    sx?: SxProps
}> = ({ leftSide, rightSide, reversedOnMobile, backgroundImage, mobileBackgroundImage, sx }) => {
    const { smScreen, mdScreen, xsScreen } = useDevice()
    const { palette } = LuneTheme

    const backgroundMaxWidth = getBackgroundMaxWidth()

    return (
        <FullScreenOnMobileWrapper
            sx={{
                position: 'relative',
                borderRadius: smScreen ? '0' : '48px',
                pt: 10,
                pb: 10,
                pl: 10,
                pr: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                gap: 16,
                // Background images don't get the "link to an resized, optimized version of
                // this image" treatment that Next.js Image does, therefore we need to it
                // manually.
                backgroundImage: `url(${optimizedImagePath({
                    // Asserting that we have actual strings here, should it ever happen that
                    // we're given undefined? If we got undefined before we had no background,
                    // nothing will change here.
                    rawUrl: smScreen ? mobileBackgroundImage! : backgroundImage!,
                    width: backgroundMaxWidth,
                })})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundColor: `${palette.Grey50}`,
                ...(mdScreen && {
                    gap: 12,
                }),
                ...(smScreen && {
                    pl: xsScreen ? 3 : 6,
                    pr: xsScreen ? 3 : 6,
                    gap: 6,
                    flexDirection: reversedOnMobile ? 'column-reverse' : 'column',
                }),
                ...(sx ?? {}),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    flex: '1 1 auto',
                    height: 'auto',
                    maxWidth: smScreen ? '100%' : '50%',
                    '> div': {
                        width: '100%',
                    },
                }}
            >
                {leftSide}
            </Box>
            <Box
                sx={{
                    flex: '1 1 auto',
                    height: 'auto',
                    width: '100%',
                    '> div': {
                        width: '100%',
                    },
                    ...(smScreen && {
                        minHeight: '345px',
                    }),
                }}
            >
                {rightSide}
            </Box>
        </FullScreenOnMobileWrapper>
    )
}

export default TwoColumnsWithBackgroundImage
